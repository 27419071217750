import React from "react";
import Header from "../../../../components/header/Header";
import Content from "../../../../components/main/content/DynamicContent";
// import Content from "../components/main/content/content";
import Sidebar from "../../../../components/main/sidebar/Sidebar";
import Footer from "../../../../components/footer/Footer";
import { graphql, Link } from "gatsby";
import "./loader.scss";
import { Helmet } from "react-helmet";
import gif from "../images/tt.gif";

const ArticlePage = (props) => {
  const { data } = props;
  const { pageContext } = props;
  const { postSearchData } = pageContext;
  const { allPosts, options } = postSearchData;

  let featured_post = data?.cms?.recent_story.filter(checkFetured);
  function checkFetured(element) {
    return element.is_feature_post;
  }

  return (
    <>
      <Helmet>
        <meta name="webgains-site-verification" content="9za3nmsz" />
        <script async src="https://www.googletagmanager.com/gtag/js?id=G-WM27LRZQNE"></script>
        <script>
          {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', 'G-WM27LRZQNE');
          `}
        </script>
        <meta name="partnerboostverifycode" content="32dc01246faccb7f5b3cad5016dd5033" />
        <meta name="lhverifycode" content="32dc01246faccb7f5b3cad5016dd5033" />
      </Helmet>
      <div className="layout">
        <div className="banner-container">
          <Link to="https://www.a88wptk.com/4RQSJ/2Z9D1KZ/" target="_blank">
            <img src={gif} alt={"banner"} />
          </Link>
        </div>
        <Header category={data?.cms?.categories} domain={data?.cms?.domain} allPosts={allPosts} engine={options} pages={data?.cms?.pages} story={data?.cms?.story} />
        <div className="container is-max-widescreen">
          <div className="columns m-0">
            <Content domain={data?.cms?.domain} story={data?.cms?.story} />
            <Sidebar recent_story={featured_post} />
          </div>
        </div>
        <Footer pages={data?.cms?.pages} domain={data?.cms?.domain} email={"editor@ofmum.com"} />
      </div>
    </>
  );
};
export default ArticlePage;

export const query = graphql`
  query article($slug: ID!) {
    cms {
      domain: CMS_Domain(id: "8") {
        id
        logo
        logo_light
        domain_url
        name
        description
        favicon
        header_script
      }
      story: CMS_Story(id: $slug) {
        id
        title
        slug
        image
        meta_description
        meta_keywords
        meta_title
        pub_date
      }
      categories: CMS_CategoryByDomain(domain_id: "8") {
        name
        slug
      }
      recent_story: CMS_DomainWiseStories(domain_id: "8") {
        image
        slug
        title
        is_feature_post
        categories {
          name
        }
      }
      pages: Published_Pages(domain_id: "1") {
        slug
        title
      }
    }
  }
`;
